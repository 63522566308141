<template>
  <div class="app-wrap">
    <h3>
      <i
        class="iconfont icon-a-fundmarkect-back"
        @click="$router.go(-1)"
      />
      安全中心
    </h3>
    <zh-step
      :index="index"
      :steps="['验证原手机', '验证新手机', '操作成功']"
    />
    <template v-if="index === 1">
      <div class="step1">
        <h4>修改绑定手机</h4>
        <p>
          已向绑定手机 <span>(+86) {{ userInfo.mobileTel.slice(0,3) }}****{{ userInfo.mobileTel.slice(-4) }}</span><br>
          发送验证短信，请注意查收
        </p>
        <el-form
          ref="step1"
          :model="step1"
          :rules="step1Rules"
          style="margin-top:62px"
        >
          <el-form-item
            style="textAlign: center"
            class="card-item"
            prop="oldPhoneCode"
          >
            <el-input
              v-model="step1.oldPhoneCode"
              placeholder="请输入验证码"
              class="pw-inpput"
              style="width: 270px !important"
            />
            <span
              class="code-b"
              :class="sendCodeDisabled ? 'disabled' : ''"
              @click="sendCode('sendCodeDisabled', 'sendCodeText')"
            >{{ sendCodeText }}</span>
          </el-form-item>
          <el-form-item
            class="card-item"
            style="textAlign: center"
          >
            <span
              class="sub-btn"
              @click="handleGoStep2"
            >下一步</span>
          </el-form-item>
        </el-form>
      </div>
    </template>
    <template v-if="index === 2">
      <div class="pw-t">
        <h3>修改绑定手机</h3>
      </div>
      <div class="step2">
        <el-form
          ref="step2"
          :model="step2"
          :rules="step2Rules"
          style="margin-top:62px"
          label-position="top"
        >
          <el-form-item
            style="textAlign: left"
            label="请输入新的绑定手机号"
            class="card-item"
            prop="newPhone"
          >
            <el-input
              v-model="step2.newPhone"
              class="input-with-select pw-inpput"
              placeholder="输入新手机号"
            >
              <el-select
                slot="prepend"
                value="0"
                class="phoneType-select"
              >
                <el-option
                  label="中国(+86)"
                  value="0"
                />
              </el-select>
            </el-input>
          </el-form-item>
          <el-form-item
            style="textAlign: left"
            :label="'点击获取验证码，短信将发送至安全手机*******' + step2.newPhone.slice(-3)"
            class="card-item"
            prop="newPhoneCode"
          >
            <el-input
              v-model="step2.newPhoneCode"
              placeholder="请输入验证码"
              class="pw-inpput"
              style="width: 270px !important"
            />
            <span
              class="code-b"
              :class="sendCodeDisabled1 ? 'disabled' : ''"
              @click="sendCode('sendCodeDisabled1', 'sendCodeText1')"
            >{{ sendCodeText1 }}</span>
          </el-form-item>
          <el-form-item style="textAlign: center">
            <span
              class="sub-btn"
              @click="handleGoStep3"
            >下一步</span>
          </el-form-item>
        </el-form>
      </div>
    </template>
    <template v-if="index === 3">
      <div class="suc-w">
        <img src="@/assets/images/myFavors/susess.png">
        <p>绑定手机修改成功！</p>
        <span
          class="suc-btn"
          @click="$router.go(-1)"
        >返回安全中心</span>
      </div>
    </template>
  </div>
</template>

<script>
import { phoneCheck, /*tradPWCheck1, tradPWCheck2*/ } from '@/utils/checkReg.js'
import { sendcode, checkVerifCode, editPhone } from "@/api/bankmanagement"
  export default {
    data() {
      return {
        sendCodeDisabled: false,
        sendCodeText: '获取验证码',
        sendCodeDisabled1: false,
        sendCodeText1: '获取验证码',
        index: 1,

        userInfo: {},

        step1: {
          oldPhoneCode: ''
        },
        step1Rules: {
          oldPhoneCode: [
            { required: true, message: '请输入短信验证码', trigger: 'blur' },
          ]
        },
        step2: {
          newPhone: '',
          newPhoneCode: ''
        },
        step2Rules: {
          newPhone: [
            { required: true, message: '请输入新绑定手机号', trigger: 'blur' },
            { pattern: phoneCheck, message: '手机号码格式不正确' }
          ],
          newPhoneCode: [
            { required: true, message: '请输入短信验证码', trigger: 'blur' },
          ]
        },

        oldPhoneUUID: '',

        passWord: '',
        againPassWord: '',
        
      }
    },
    created () {
      this.userInfo = JSON.parse(localStorage.getItem('customerInfo')) || {}
    },
    methods: {
      sendCode(disabled, text)  {
        if (this[disabled]) {
          return
        }
        sendcode({
          phone: disabled === 'sendCodeDisabled' ? this.userInfo.mobileTel : this.step2.newPhone,
          verifCode: disabled === 'sendCodeDisabled' ? "3" : '6',
          uuid: disabled === 'sendCodeDisabled' ? undefined : this.oldPhoneUUID,
          oldPhone: disabled === 'sendCodeDisabled' ? undefined : this.userInfo.mobileTel
        }).then(() => {
          this[disabled] = true
          this.countDown(disabled, text)
        })
      },
      countDown(disabled, text) {
        let num = 60
        this[text] = `${num}s后重新获取`
        const timer = setInterval(() => {
          console.log(num)
          num--
          if (num < 1) {
            clearInterval(timer)
            this[text] = `获取验证码`
            this[disabled] = false
          } else {
            this[text] = `${num}s后重新获取`
          }
        }, 1000);
        this.$once('hook:destroyed', () => {
          clearInterval(timer)
        })
      },
      handleGoStep2() {
        this.$refs['step1'].validate((valid) => {
          if (valid) {
            checkVerifCode({
              code: this.step1.oldPhoneCode,
              phone: this.userInfo.mobileTel,
              verifCode: "3"
            }).then(({data}) => {
              this.oldPhoneUUID = data
              this.index = 2
            })
          }
        })
      },
      handleGoStep3() {
        this.$refs['step2'].validate((valid) => {
          if (valid) {
            checkVerifCode({
              code: this.step2.newPhoneCode,
              phone: this.step2.newPhone,
              verifCode: "6"
            }).then(({data}) => {
              editPhone({
                clientSource: '1',
                mobileTel: this.step2.newPhone,
                uuid: data
              }).then(() => {
                this.index = 3
              })
            })
          }
        })
      }
    },
  }
</script>

<style lang="less" scoped>
@import url('@/assets/css/zc-common.less');
.app-wrap > h3 {
  .flex(flex-start, center, row);
  font-size: 20px;
  color: #1F1F1F;
  padding-bottom: 30px;
  border-bottom: 1px solid #EDF1F7;
  font-weight: normal;
  i {
    font-size:18px;
    color: #BDC0CB;
    margin-right: 14px;
  }
}
.pw-t {
  width: 100%;
  margin-top: 40px;
  &>h3 {
    font-weight: normal;
    font-size: 24px;
    color: #25293D;
    text-align: center;
  }
  &>p {
    margin-top: 22px;
    font-size: 12px;
    color: #BDC0CB;
    text-align: center;
  }
}

.sub-btn {
  .btn(450px, 50px);
  .inline-flex(center, center, row);
}

.suc-w {
  width: 100%;
  .flex(center, center, column);
  margin-top: 44px;
  &>img {
    width: 120px;
    height: 120px;
  }
  p {
    width: 100%;
    font-weight: bold;
    font-size: 18px;
    color: #25293D;
    text-align: center;
    margin-top: 54px;
  }
  .suc-btn {
    margin-top: 40px;
    .inline-flex(center, center, row);
    width: 350px;
    height: 50px;
    background: linear-gradient(109.88deg, #F7D9B7 -3.02%, #EABF96 98.27%);
    border-radius: 4px;
    font-size: 16px;
    color: #B88141;
  }
}
.step1 {
  margin-top: 44px;
  width: 100%;
  .flex(flex-start, center, column);
  &>h4 {
    font-weight: normal;
    font-size: 24px;
    color: #25293D;
  }
  &>p{
    font-size: 14px;
    color: #BDC0CB;
    margin-top: 22px;
    text-align: center;
    span {
      color: #CE9B63;
    }
  }
  
}
.step2 {
  .flex(center, center, column); 
}
.code-b {
  .inline-flex(center, center, row);
  width: 155px;
  height: 50px;
  border: 0.6px solid #CE9B63;
  border-radius: 4px;
  color: #CE9B63;
  font-size: 18px;
  margin-left: 24px;
  cursor: pointer;
  &.disabled {
    border: 0.6px solid #BDC0CB;
    color: #BDC0CB;
  }
}
</style>
<style>
.pw-inpput {
  width:450px !important;
  height:50px !important
}
.pw-inpput .el-input__inner {
  height:50px !important;
  line-height: 50px !important;
}
.pw-inpput .el-input__suffix {
  right: 20px !important;
  display: flex;
  align-items: center;
}
.input-with-select .el-input-group__prepend {
  width: 65px !important;
}
.card-item .el-form-item__label{
  font-size: 14px !important;
  color: #BDC0CB !important;
  padding: 0 !important;
}
.card-item .el-form-item__content {
  display: flex !important;
}
.phoneType-select .el-input__inner {
  padding-left: 10px !important;
  padding-right: 0 !important;
}
.phoneType-select .el-input--suffix .el-input__suffix {
    right: 10px !important;
}
</style>